<template>
  <div class="weatherFeed border-2 rounded">
      <!-- {{weatherFeed}} -->
      <div v-for="(dayData, index) in weatherFeed.daily" :key="dayData">
          <div v-if="index <= 2" class="dailyWeather my-8 border-t border-b mx-6 rounded shadow-xl p-4">
            <img :src="'http://openweathermap.org/img/wn/' + dayData.weather[0].icon + '@2x.png'" alt="" class="mx-auto">
            <h3 class="text-2xl font-bold">{{formatDate(dayData.dt)}}</h3>
              <p>Weerbericht: <strong>{{dayData.weather[0].description}} - {{dayData.temp.day}} °C</strong></p>
            <p>Temperatuur: Minimaal: {{dayData.temp.min}} °C Maximaal: {{dayData.temp.max}} °C</p>
            <p>UV-index: {{dayData.uvi}}</p>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Weather',
    data(){
        return{
            weatherFeed: [],
            timer: ''
        }
    },
    mounted () {
        axios
            .get('https://api.openweathermap.org/data/2.5/onecall?lat=51.965&lon=6.2889&appid=7a864c4515cf08010f851d3f5b4bda40&lang=nl&units=metric')
            .then(function(response){
                console.log(response.data);
                localStorage.weatherFeed = JSON.stringify(response.data);
            });


        if(localStorage.weatherFeed){
            this.weatherFeed = JSON.parse(localStorage.weatherFeed);
        }
        
    },
     created () {
        this.timer = setInterval(this.fetchDataList, 600000);
    },
    methods: {
        fetchDataList(){
            axios
            .get('https://api.openweathermap.org/data/2.5/onecall?lat=51.965&lon=6.2889&appid=7a864c4515cf08010f851d3f5b4bda40&lang=nl&units=metric')
            .then(function(response){
                console.log(response.data);
                localStorage.weatherFeed = JSON.stringify(response.data);
            });


            if(localStorage.weatherFeed){
                this.weatherFeed = JSON.parse(localStorage.weatherFeed);
            }
        },
        cancelAutoUpdate () {
            clearInterval(this.timer);
        },
        formatDate(date){
            console.log(new Date(date * 1000));
            return new Date(date*1000).toLocaleDateString('nl-NL');
        }
    },
    beforeUnmount () {
      this.cancelAutoUpdate();
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.weatherFeed{
    background-color: white;
    max-height: 74vh;
    height: 100%;
    /* overflow-y: scroll; */
}

.dailyWeather{
    background-color: lightskyblue;
}
</style>
