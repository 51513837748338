<template>
  <div class="newsFeed rounded" id="scrollFeed">
    <div
      class="dailyNews my-8 border-t border-b mx-6"
      v-for="newsData in newsFeed"
      :key="newsData"
    >
      <img :src="newsData.image" alt="" class="mx-auto mb-6 max-h-44 w-full object-cover" />
      <h3 class="text-2xl">{{ newsData.title }}</h3>
      <p class="mb-6">{{ newsData.description }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// 2642fcb33c3f4e1b8dff4ea7871cc4d2
export default {
  name: "News",

  data() {
    return {
      newsFeed: null,
      timer: "",
    };
  },
  beforeMount() {
    axios
      .get(
        "https://gnews.io/api/v4/top-headlines?token=e3f03594d01fe8c811060be9cb737867&lang=nl&topic=technology"
      )
      .then(function (response) {
        console.log(response.data);
        localStorage.newsFeed = JSON.stringify(response.data.articles);
      });
    if (localStorage.newsFeed) {
      this.newsFeed = JSON.parse(localStorage.newsFeed);
    }
  },
  created() {
    this.timer = setInterval(this.fetchDataList, 600000);

  },
  mounted() {
    this.pageScroll();
  },
  methods: {
    fetchDataList() {
      axios
        .get(
          "https://gnews.io/api/v4/top-headlines?token=e3f03594d01fe8c811060be9cb737867&lang=nl&topic=technology"
        )
        .then(function (response) {
          //console.log(response.data);
          localStorage.newsFeed = JSON.stringify(response.data.articles);
        });
      if (localStorage.newsFeed) {
        this.newsFeed = JSON.parse(localStorage.newsFeed);
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    pageScroll() {
      var newsScroller = document.getElementById('scrollFeed');
      console.log(newsScroller.scrollY);
      // if((newsScroller.innerHeight + newsScroller.scrollY) >= 800)
      window.setInterval(() => {
        // If at page bottom, scroll by to top
        var newsScroller = document.getElementById('scrollFeed');
        if(newsScroller.scrollTop >= newsScroller.scrollHeight)
           newsScroller.scrollTo(0, 0)
        else
          // console.log(newsScroller.scrollHeight);
        // 2664 , 4030
        // console.log(newsScroller.offsetHeight);
        //         // Scroll 1px down
          newsScroller.scrollBy({
            top: 5,
            left: 0,
            behavior: 'smooth'
          })
        // console.log(newsScroller.offsetHeight);
      }, 200)
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.newsFeed {
  background-color: white;
  max-height: 94vh;
  height: 100%;
  overflow-y: scroll;
}
</style>
