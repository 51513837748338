<template>
  <div class="olcTV flex">
      <div class="2column w-1/4 ml-8 mt-8">
          <clock></clock>
          <weather class=""></weather>
      </div>
      <div class="w-3/4">
          <div class="topRow p-8 flex gap-8">
              <news class="w-2/5"></news>
              <public-transport class="w-3/5"></public-transport>
<!--              <MusicChart></MusicChart>-->
          </div>
<!--          <div class="bottomRow p-8 flex gap-8">-->
<!--              <MoviesChart></MoviesChart>-->

<!--          </div>-->
      </div>

  </div>


</template>

<script>
import News from './components/News.vue';
import Weather from './components/Weather.vue';
import PublicTransport from './components/PublicTransport.vue';
import Clock from './components/Clock.vue';
// import MusicChart from "./components/MusicChart";
// import MoviesChart from "./components/MoviesChart";

export default {
  name: 'App',
  components: {
        News,
        Weather,
        PublicTransport,
        Clock,
      //   MusicChart,
      // MoviesChart,
  }
}
</script>

<style>
.olcTV{
  background-image: url('assets/gc_background.jpg');
  background-size: cover;
  min-height: 100vh;
}
</style>
