<template>
  <div class="clockDisplay text-center  flex items-center justify-center rounded">
      <div class="clockBox">
        <div class="clock-border mx-auto inline-block">
            <div class="clock-inner flex" :class="color">
                <div class="hour">{{hours}}</div>
                <div class="dots">:</div>
                <div class="min">{{minutes}}</div>
                <div class="dots">:</div>
                <div class="secs">{{seconds}}</div>
                <div class="mode"></div>
            </div>
        </div>
        <h2 class="text-2xl">{{currentDate}}</h2>
      </div>
  </div>
</template>

<script>
export default {
    name: 'Clock',
    data () {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  methods: {
    setTime () {
      setInterval(() => {
        const date = new Date()
        this.hours = date.getHours()
        this.minutes = this.checkSingleDigit(date.getMinutes())
        this.seconds = this.checkSingleDigit(date.getSeconds())
      }, 1000)
    },
    checkSingleDigit (digit) {
      return ('0' + digit).slice(-2)
    }
  },
  mounted () {
    this.setTime()
  },
    computed: {
        currentDate: function(){
            return new Date().toLocaleDateString('nl-NL');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clockDisplay{
    background-color: white;
    height: 250px;
    margin-bottom: 40px;
}
.clock-inner{
    font-size: 80px;
}
</style>
