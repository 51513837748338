<template>
  <div class="flex">
        <div class="publicTransport py-6 rounded">
            <h2 class="mx-6 text-3xl mb-6 font-bold">Halte: Doetinchem Station</h2>
            <div class="dailyNews mx-2 py-6 my-2 px-4" v-for="transportData in transportFeedStation" :key="transportData">
            <h3 class="text-2xl">{{ transportData['busLijn'] }} naar {{ transportData['destination'] }}</h3>
                <p class="text-xl">Vertrektijd: <strong>{{ formatDate(transportData['vertrekTijd']) }}</strong></p>
            </div>
        </div>
        <div class="ml-8 publicTransport py-6 rounded">
            <h2 class="mx-6 text-3xl mb-6 font-bold">Halte: Doetinchem Graafschap College</h2>
            <div class="dailyNews mx-2 py-6 my-2 px-4" v-for="transportData in transportFeedSchool" :key="transportData">
                <h3 class="text-2xl">{{ transportData['busLijn'] }} naar {{ transportData['destination'] }}</h3>
                <p class="text-xl">Vertrektijd: <strong>{{ formatDate(transportData['vertrekTijd']) }}</strong></p>
            </div>
        </div>
       
  </div>
</template>

<script>
import axios from 'axios';

// 2642fcb33c3f4e1b8dff4ea7871cc4d2
export default {
    name: 'PublicTransport',
    
    data(){
        return{
            transportFeedStation: null,
            transportFeedSchool: null,
            timer: ''
        }
    },
    beforeMount () {
        var that = this;
        axios
        .get('https://www.whateverorigin.org/get?url=' + encodeURIComponent('http://kv78turbo.ovapi.nl/stopareacode/DtcSta,DtcGra/departures') + '&callback=?')
        .then(function(response){
                //console.log(response.data);
                // localStorage.transportFeedStation = JSON.stringify(response.data.DtcSta);
                
                var busArray = [];
                for(var busData in response.data.DtcGra){
                    // console.log(response.data.DtcGra[busData].Passes);
                   for(var busInfo in response.data.DtcGra[busData].Passes){
                        var busItem = [];
                        busItem['busLijn'] = response.data.DtcGra[busData].Passes[busInfo].LinePublicNumber;
                        busItem['destination'] = response.data.DtcGra[busData].Passes[busInfo].DestinationName50;
                        busItem['vertrekTijd'] = Date.parse(response.data.DtcGra[busData].Passes[busInfo].ExpectedDepartureTime);
    
                        busArray.push(busItem);
                    }
                }
                busArray.sort((a, b) => a['vertrekTijd'] - b['vertrekTijd']);
                // console.log(busArray);
                that.transportFeedSchool = busArray;

                var stArray = [];
                for(var busData2 in response.data.DtcSta){
                    // console.log(response.data.DtcGra[busData].Passes);
                   for(var busInfo2 in response.data.DtcSta[busData2].Passes){
                        var busItem2 = [];
                        busItem2['busLijn'] = response.data.DtcSta[busData2].Passes[busInfo2].LinePublicNumber;
                        busItem2['destination'] = response.data.DtcSta[busData2].Passes[busInfo2].DestinationName50;
                        busItem2['vertrekTijd'] = Date.parse(response.data.DtcSta[busData2].Passes[busInfo2].ExpectedDepartureTime);
    
                        stArray.push(busItem2);
                    }
                }
                stArray.sort((a, b) => a['vertrekTijd'] - b['vertrekTijd']);
                console.log(stArray);
                that.transportFeedStation = stArray;
            });
    },
    created () {
        this.timer = setInterval(this.fetchDataList, 10000);
    },
    methods: {
        fetchDataList(){
            console.log('newData');
            var that = this;
            axios
            .get('https://www.whateverorigin.org/get?url=' + encodeURIComponent('http://kv78turbo.ovapi.nl/stopareacode/DtcSta,DtcGra/departures') + '&callback=?')
            .then(function(response){
                    //console.log(response.data);
                    //localStorage.transportFeedStation = JSON.stringify(response.data.DtcSta);
                    
                    var busArray = [];
                    for(var busData in response.data.DtcGra){
                        // console.log(response.data.DtcGra[busData].Passes);
                    for(var busInfo in response.data.DtcGra[busData].Passes){
                            var busItem = [];
                            busItem['busLijn'] = response.data.DtcGra[busData].Passes[busInfo].LinePublicNumber;
                            busItem['destination'] = response.data.DtcGra[busData].Passes[busInfo].DestinationName50;
                            busItem['vertrekTijd'] = Date.parse(response.data.DtcGra[busData].Passes[busInfo].ExpectedDepartureTime);
        
                            busArray.push(busItem);
                        }
                    }
                    busArray.sort((a, b) => a['vertrekTijd'] - b['vertrekTijd']);
                    // console.log(busArray);
                    that.transportFeedSchool = busArray;
                    
                    var stArray = [];
                    for(var busData2 in response.data.DtcSta){
                        // console.log(response.data.DtcGra[busData].Passes);
                        for(var busInfo2 in response.data.DtcSta[busData2].Passes){
                            var busItem2 = [];
                            busItem2['busLijn'] = response.data.DtcSta[busData2].Passes[busInfo2].LinePublicNumber;
                            busItem2['destination'] = response.data.DtcSta[busData2].Passes[busInfo2].DestinationName50;
                            busItem2['vertrekTijd'] = Date.parse(response.data.DtcSta[busData2].Passes[busInfo2].ExpectedDepartureTime);
        
                            stArray.push(busItem2);
                        }
                    }
                    stArray.sort((a, b) => a['vertrekTijd'] - b['vertrekTijd']);
                    // console.log(busArray);
                    that.transportFeedStation = stArray;
                });
        },
        formatDate(date){
            // console.log(new Date(date));
            return new Date(date).toLocaleTimeString('nl-NL');
        },
        cancelAutoUpdate () {
            clearInterval(this.timer);
        }
    },
    beforeUnmount () {
      this.cancelAutoUpdate();
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.publicTransport{
    background-color: white;
    max-height: 94vh;
    overflow-y: scroll;
}
.dailyNews:nth-child(2n+3){
    background-color: lightgrey;
}
</style>
